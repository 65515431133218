import { default as aboutvTVcm8GxvFMeta } from "/var/repos/sewmee/shop/pages/about.vue?macro=true";
import { default as indexLuBmNC6dgKMeta } from "/var/repos/sewmee/shop/pages/cloth_orders/index.vue?macro=true";
import { default as _91id_93eZsBYlQQgfMeta } from "/var/repos/sewmee/shop/pages/clothes/[id].vue?macro=true";
import { default as ___indexXJoqo3fnovMeta } from "/var/repos/sewmee/shop/pages/clothes/___index.vue?macro=true";
import { default as indexI4UMzCMca2Meta } from "/var/repos/sewmee/shop/pages/clothes/index.vue?macro=true";
import { default as commerceYpOMXKvcHrMeta } from "/var/repos/sewmee/shop/pages/commerce.vue?macro=true";
import { default as signupcJtUIJjIu7Meta } from "/var/repos/sewmee/shop/pages/creators/signup.vue?macro=true";
import { default as clotheszPrmAdMSJBMeta } from "/var/repos/sewmee/shop/pages/favorites/clothes.vue?macro=true";
import { default as creatorsGWVFhwvYepMeta } from "/var/repos/sewmee/shop/pages/favorites/creators.vue?macro=true";
import { default as productsE2UGxagkE3Meta } from "/var/repos/sewmee/shop/pages/favorites/products.vue?macro=true";
import { default as indexZT204gS3nBMeta } from "/var/repos/sewmee/shop/pages/index.vue?macro=true";
import { default as informationsUYEVe1cbkKMeta } from "/var/repos/sewmee/shop/pages/informations.vue?macro=true";
import { default as inquirygqDtwPqSa8Meta } from "/var/repos/sewmee/shop/pages/inquiry.vue?macro=true";
import { default as _91_46_46_46slug_93yaW8YM5ABmMeta } from "/var/repos/sewmee/shop/pages/member/[...slug].vue?macro=true";
import { default as notificationsbmj4NxC2inMeta } from "/var/repos/sewmee/shop/pages/notifications.vue?macro=true";
import { default as order_messagesKUo6MfdCoBMeta } from "/var/repos/sewmee/shop/pages/order_messages.vue?macro=true";
import { default as privacyTwJCh5UVmSMeta } from "/var/repos/sewmee/shop/pages/privacy.vue?macro=true";
import { default as _91id_93uhDCDSDEBhMeta } from "/var/repos/sewmee/shop/pages/product_orders/[id].vue?macro=true";
import { default as _91id_93HommzZu4KYMeta } from "/var/repos/sewmee/shop/pages/products/[id].vue?macro=true";
import { default as indexpVH8dMrESAMeta } from "/var/repos/sewmee/shop/pages/products/index.vue?macro=true";
import { default as sewmee8eVTgCVF0qMeta } from "/var/repos/sewmee/shop/pages/sewmee.vue?macro=true";
import { default as signindfqnAa1rD9Meta } from "/var/repos/sewmee/shop/pages/signin.vue?macro=true";
import { default as signoutOvVCLlOdpPMeta } from "/var/repos/sewmee/shop/pages/signout.vue?macro=true";
import { default as signupEG9LoiBfnUMeta } from "/var/repos/sewmee/shop/pages/signup.vue?macro=true";
import { default as signupsiJhCPPM4AMeta } from "/var/repos/sewmee/shop/pages/suppliers/signup.vue?macro=true";
import { default as terms86FBWXBuNPMeta } from "/var/repos/sewmee/shop/pages/terms.vue?macro=true";
export default [
  {
    name: aboutvTVcm8GxvFMeta?.name ?? "about",
    path: aboutvTVcm8GxvFMeta?.path ?? "/about",
    meta: aboutvTVcm8GxvFMeta || {},
    alias: aboutvTVcm8GxvFMeta?.alias || [],
    redirect: aboutvTVcm8GxvFMeta?.redirect || undefined,
    component: () => import("/var/repos/sewmee/shop/pages/about.vue").then(m => m.default || m)
  },
  {
    name: indexLuBmNC6dgKMeta?.name ?? "cloth_orders",
    path: indexLuBmNC6dgKMeta?.path ?? "/cloth_orders",
    meta: indexLuBmNC6dgKMeta || {},
    alias: indexLuBmNC6dgKMeta?.alias || [],
    redirect: indexLuBmNC6dgKMeta?.redirect || undefined,
    component: () => import("/var/repos/sewmee/shop/pages/cloth_orders/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93eZsBYlQQgfMeta?.name ?? "clothes-id",
    path: _91id_93eZsBYlQQgfMeta?.path ?? "/clothes/:id()",
    meta: _91id_93eZsBYlQQgfMeta || {},
    alias: _91id_93eZsBYlQQgfMeta?.alias || [],
    redirect: _91id_93eZsBYlQQgfMeta?.redirect || undefined,
    component: () => import("/var/repos/sewmee/shop/pages/clothes/[id].vue").then(m => m.default || m)
  },
  {
    name: ___indexXJoqo3fnovMeta?.name ?? "clothes-___index",
    path: ___indexXJoqo3fnovMeta?.path ?? "/clothes/___index",
    meta: ___indexXJoqo3fnovMeta || {},
    alias: ___indexXJoqo3fnovMeta?.alias || [],
    redirect: ___indexXJoqo3fnovMeta?.redirect || undefined,
    component: () => import("/var/repos/sewmee/shop/pages/clothes/___index.vue").then(m => m.default || m)
  },
  {
    name: indexI4UMzCMca2Meta?.name ?? "clothes",
    path: indexI4UMzCMca2Meta?.path ?? "/clothes",
    meta: indexI4UMzCMca2Meta || {},
    alias: indexI4UMzCMca2Meta?.alias || [],
    redirect: indexI4UMzCMca2Meta?.redirect || undefined,
    component: () => import("/var/repos/sewmee/shop/pages/clothes/index.vue").then(m => m.default || m)
  },
  {
    name: commerceYpOMXKvcHrMeta?.name ?? "commerce",
    path: commerceYpOMXKvcHrMeta?.path ?? "/commerce",
    meta: commerceYpOMXKvcHrMeta || {},
    alias: commerceYpOMXKvcHrMeta?.alias || [],
    redirect: commerceYpOMXKvcHrMeta?.redirect || undefined,
    component: () => import("/var/repos/sewmee/shop/pages/commerce.vue").then(m => m.default || m)
  },
  {
    name: signupcJtUIJjIu7Meta?.name ?? "creators-signup",
    path: signupcJtUIJjIu7Meta?.path ?? "/creators/signup",
    meta: signupcJtUIJjIu7Meta || {},
    alias: signupcJtUIJjIu7Meta?.alias || [],
    redirect: signupcJtUIJjIu7Meta?.redirect || undefined,
    component: () => import("/var/repos/sewmee/shop/pages/creators/signup.vue").then(m => m.default || m)
  },
  {
    name: clotheszPrmAdMSJBMeta?.name ?? "favorites-clothes",
    path: clotheszPrmAdMSJBMeta?.path ?? "/favorites/clothes",
    meta: clotheszPrmAdMSJBMeta || {},
    alias: clotheszPrmAdMSJBMeta?.alias || [],
    redirect: clotheszPrmAdMSJBMeta?.redirect || undefined,
    component: () => import("/var/repos/sewmee/shop/pages/favorites/clothes.vue").then(m => m.default || m)
  },
  {
    name: creatorsGWVFhwvYepMeta?.name ?? "favorites-creators",
    path: creatorsGWVFhwvYepMeta?.path ?? "/favorites/creators",
    meta: creatorsGWVFhwvYepMeta || {},
    alias: creatorsGWVFhwvYepMeta?.alias || [],
    redirect: creatorsGWVFhwvYepMeta?.redirect || undefined,
    component: () => import("/var/repos/sewmee/shop/pages/favorites/creators.vue").then(m => m.default || m)
  },
  {
    name: productsE2UGxagkE3Meta?.name ?? "favorites-products",
    path: productsE2UGxagkE3Meta?.path ?? "/favorites/products",
    meta: productsE2UGxagkE3Meta || {},
    alias: productsE2UGxagkE3Meta?.alias || [],
    redirect: productsE2UGxagkE3Meta?.redirect || undefined,
    component: () => import("/var/repos/sewmee/shop/pages/favorites/products.vue").then(m => m.default || m)
  },
  {
    name: indexZT204gS3nBMeta?.name ?? "index",
    path: indexZT204gS3nBMeta?.path ?? "/",
    meta: indexZT204gS3nBMeta || {},
    alias: indexZT204gS3nBMeta?.alias || [],
    redirect: indexZT204gS3nBMeta?.redirect || undefined,
    component: () => import("/var/repos/sewmee/shop/pages/index.vue").then(m => m.default || m)
  },
  {
    name: informationsUYEVe1cbkKMeta?.name ?? "informations",
    path: informationsUYEVe1cbkKMeta?.path ?? "/informations",
    meta: informationsUYEVe1cbkKMeta || {},
    alias: informationsUYEVe1cbkKMeta?.alias || [],
    redirect: informationsUYEVe1cbkKMeta?.redirect || undefined,
    component: () => import("/var/repos/sewmee/shop/pages/informations.vue").then(m => m.default || m)
  },
  {
    name: inquirygqDtwPqSa8Meta?.name ?? "inquiry",
    path: inquirygqDtwPqSa8Meta?.path ?? "/inquiry",
    meta: inquirygqDtwPqSa8Meta || {},
    alias: inquirygqDtwPqSa8Meta?.alias || [],
    redirect: inquirygqDtwPqSa8Meta?.redirect || undefined,
    component: () => import("/var/repos/sewmee/shop/pages/inquiry.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93yaW8YM5ABmMeta?.name ?? "member-slug",
    path: _91_46_46_46slug_93yaW8YM5ABmMeta?.path ?? "/member/:slug(.*)*",
    meta: _91_46_46_46slug_93yaW8YM5ABmMeta || {},
    alias: _91_46_46_46slug_93yaW8YM5ABmMeta?.alias || [],
    redirect: _91_46_46_46slug_93yaW8YM5ABmMeta?.redirect || undefined,
    component: () => import("/var/repos/sewmee/shop/pages/member/[...slug].vue").then(m => m.default || m)
  },
  {
    name: notificationsbmj4NxC2inMeta?.name ?? "notifications",
    path: notificationsbmj4NxC2inMeta?.path ?? "/notifications",
    meta: notificationsbmj4NxC2inMeta || {},
    alias: notificationsbmj4NxC2inMeta?.alias || [],
    redirect: notificationsbmj4NxC2inMeta?.redirect || undefined,
    component: () => import("/var/repos/sewmee/shop/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: order_messagesKUo6MfdCoBMeta?.name ?? "order_messages",
    path: order_messagesKUo6MfdCoBMeta?.path ?? "/order_messages",
    meta: order_messagesKUo6MfdCoBMeta || {},
    alias: order_messagesKUo6MfdCoBMeta?.alias || [],
    redirect: order_messagesKUo6MfdCoBMeta?.redirect || undefined,
    component: () => import("/var/repos/sewmee/shop/pages/order_messages.vue").then(m => m.default || m)
  },
  {
    name: privacyTwJCh5UVmSMeta?.name ?? "privacy",
    path: privacyTwJCh5UVmSMeta?.path ?? "/privacy",
    meta: privacyTwJCh5UVmSMeta || {},
    alias: privacyTwJCh5UVmSMeta?.alias || [],
    redirect: privacyTwJCh5UVmSMeta?.redirect || undefined,
    component: () => import("/var/repos/sewmee/shop/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: _91id_93uhDCDSDEBhMeta?.name ?? "product_orders-id",
    path: _91id_93uhDCDSDEBhMeta?.path ?? "/product_orders/:id()",
    meta: _91id_93uhDCDSDEBhMeta || {},
    alias: _91id_93uhDCDSDEBhMeta?.alias || [],
    redirect: _91id_93uhDCDSDEBhMeta?.redirect || undefined,
    component: () => import("/var/repos/sewmee/shop/pages/product_orders/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93HommzZu4KYMeta?.name ?? "products-id",
    path: _91id_93HommzZu4KYMeta?.path ?? "/products/:id()",
    meta: _91id_93HommzZu4KYMeta || {},
    alias: _91id_93HommzZu4KYMeta?.alias || [],
    redirect: _91id_93HommzZu4KYMeta?.redirect || undefined,
    component: () => import("/var/repos/sewmee/shop/pages/products/[id].vue").then(m => m.default || m)
  },
  {
    name: indexpVH8dMrESAMeta?.name ?? "products",
    path: indexpVH8dMrESAMeta?.path ?? "/products",
    meta: indexpVH8dMrESAMeta || {},
    alias: indexpVH8dMrESAMeta?.alias || [],
    redirect: indexpVH8dMrESAMeta?.redirect || undefined,
    component: () => import("/var/repos/sewmee/shop/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: sewmee8eVTgCVF0qMeta?.name ?? "sewmee",
    path: sewmee8eVTgCVF0qMeta?.path ?? "/sewmee",
    meta: sewmee8eVTgCVF0qMeta || {},
    alias: sewmee8eVTgCVF0qMeta?.alias || [],
    redirect: sewmee8eVTgCVF0qMeta?.redirect || undefined,
    component: () => import("/var/repos/sewmee/shop/pages/sewmee.vue").then(m => m.default || m)
  },
  {
    name: signindfqnAa1rD9Meta?.name ?? "signin",
    path: signindfqnAa1rD9Meta?.path ?? "/signin",
    meta: signindfqnAa1rD9Meta || {},
    alias: signindfqnAa1rD9Meta?.alias || [],
    redirect: signindfqnAa1rD9Meta?.redirect || undefined,
    component: () => import("/var/repos/sewmee/shop/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: signoutOvVCLlOdpPMeta?.name ?? "signout",
    path: signoutOvVCLlOdpPMeta?.path ?? "/signout",
    meta: signoutOvVCLlOdpPMeta || {},
    alias: signoutOvVCLlOdpPMeta?.alias || [],
    redirect: signoutOvVCLlOdpPMeta?.redirect || undefined,
    component: () => import("/var/repos/sewmee/shop/pages/signout.vue").then(m => m.default || m)
  },
  {
    name: signupEG9LoiBfnUMeta?.name ?? "signup",
    path: signupEG9LoiBfnUMeta?.path ?? "/signup",
    meta: signupEG9LoiBfnUMeta || {},
    alias: signupEG9LoiBfnUMeta?.alias || [],
    redirect: signupEG9LoiBfnUMeta?.redirect || undefined,
    component: () => import("/var/repos/sewmee/shop/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: signupsiJhCPPM4AMeta?.name ?? "suppliers-signup",
    path: signupsiJhCPPM4AMeta?.path ?? "/suppliers/signup",
    meta: signupsiJhCPPM4AMeta || {},
    alias: signupsiJhCPPM4AMeta?.alias || [],
    redirect: signupsiJhCPPM4AMeta?.redirect || undefined,
    component: () => import("/var/repos/sewmee/shop/pages/suppliers/signup.vue").then(m => m.default || m)
  },
  {
    name: terms86FBWXBuNPMeta?.name ?? "terms",
    path: terms86FBWXBuNPMeta?.path ?? "/terms",
    meta: terms86FBWXBuNPMeta || {},
    alias: terms86FBWXBuNPMeta?.alias || [],
    redirect: terms86FBWXBuNPMeta?.redirect || undefined,
    component: () => import("/var/repos/sewmee/shop/pages/terms.vue").then(m => m.default || m)
  }
]